//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.form-control{border-radius: 0 !important;    border: 1px solid #000 !important;background: transparent !important;background-color: transparent !important;box-shadow: none !important;}
.form-select{border-radius: 0 !important;    border: 1px solid #000 !important;background-color: transparent !important;box-shadow: none !important;}
.aside{background-color: #163C68 !important;}
.basic-multi-select .select__control{border-color: #000 !important;}
.form-check-input:checked{background-color: #163C68 !important;}
.text-info{color: #163C68 !important;}
.form-switch .form-check-input{font-size:initial;}

.cell-wrap-text {white-space: normal !important;}
.ag-row .ag-cell {display: flex; align-items: center;}
.ap-btn{background-color: #163C68 !important; color: #fff !important;}
.header{box-shadow: none !important;}
.ap-padLeft{padding-left: 50px;}
.ap-pad-left-cat{padding-left: 50px;}
.navigation-link-info-dp{display: flex;align-items:center}
.navigation-link-info-dp .dropdown-toggle{color: #fff !important;text-align: left;padding: 0 !important;font-size: 12px !important;}
.display-5{font-size:32px !important;}
.card-header.card-header-border-1 .h5.fw-light{font-size: 14px !important;height: 50px !important;}

.ap-rowFlex .form-switch{display: flex;align-items: center;}
.ap-rowFlex .form-switch .form-check-input{display: inline-block;margin-right: 10px;margin-top: 0px;}